import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

import AdvertisersService from '@master/Services/Cache/AdvertisersService';
import CreativeSizeService from '@master/Services/CreativeSizeService';
import DSPService from '@master/Services/Cache/DSPService';
import FilterService from '@master/Services/FilterService';
import HttpService from '@master/Services/HttpService';
import IntercomService from '@master/Services/IntercomService';
import KeywordsService from '@master/Services/Cache/KeywordsService';
import NotificationService from '@master/Services/NotificationService';
import UserService from '@master/Services/UserService';
import UsersService from '@master/Services/Cache/UsersService';
import WhitelabelService from '@master/Services/WhitelabelService';

import { appendScript } from '@helpers/Global';
import constants from '@master/constants';

const GlobalServicePlugin = {
  install: (Vue, router) => {
    // disable console tooltips about using the development environment when developing the app
    Vue.config.productionTip = false;

    if (process.env.VUE_APP_API == null) {
      throw new Error('VUE_APP_API is undefined');
    }

    Vue.prototype.$appid = process.env.VUE_APP_APPID;
    Vue.prototype.$env = process.env.VUE_APP_ENV;

    Vue.prototype.$ad = process.env.VUE_APP_AD;
    Vue.prototype.$admin = process.env.VUE_APP_ADMIN;
    Vue.prototype.$adspecs = process.env.VUE_APP_ADSPECS;
    Vue.prototype.$api = process.env.VUE_APP_API;
    Vue.prototype.$auth = process.env.VUE_APP_AUTH;
    Vue.prototype.$base = process.env.VUE_APP_BASE;
    Vue.prototype.$cdn = process.env.VUE_APP_CDN;
    Vue.prototype.$cm = process.env.VUE_APP_CM;
    Vue.prototype.$developer = process.env.VUE_APP_DEV;
    Vue.prototype.$optimizer = process.env.VUE_APP_OPTIMIZER;
    Vue.prototype.$preview = process.env.VUE_APP_PREVIEW;

    Vue.prototype.$version = process.env.VUE_APP_VERSION || new Date().getTime().toString();

    // some legacy bs probably, if you see this and recall why we need those window globals write it down :D
    window.__nexd = {
      appid: Vue.prototype.$appid,
      env: Vue.prototype.$env,

      base: Vue.prototype.$base,
      cdn: Vue.prototype.$cdn,
      cm: Vue.prototype.$cm,
      api: Vue.prototype.$api,
      optimizer: Vue.prototype.$optimizer,
      ad: Vue.prototype.$ad,
      preview: Vue.prototype.$preview,
      admin: Vue.prototype.$admin,
      developer: Vue.prototype.$developer,

      version: Vue.prototype.$version,
    };

    HttpService.setEndpoint(Vue.prototype.$api);
    HttpService.setRouter(router);

    const match = /[?&]partner=([^&]*)/i.exec(window.location.href);
    let partner = null;
    if (match) {
      partner = decodeURIComponent(match[1].replace(/\+/g, ' '));
    }

    // init after dataservice, as it relies on the endpoint
    WhitelabelService.init(partner);

    // init services
    AdvertisersService.init(Vue, router);
    CreativeSizeService.init(Vue, router);
    DSPService.init(Vue, router);
    FilterService.init(Vue, router);
    KeywordsService.init(Vue, router);
    UsersService.init(Vue, router);

    if (process.env.VUE_APP_BUGSNAG_API_KEY) {
      window._bugsnag = Bugsnag.start({
        apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
        appVersion: process.env.VUE_APP_VERSION,
        releaseStage: process.env.VUE_APP_ENV,
        plugins: [new BugsnagPluginVue()],
        onError: event => {
          if (typeof event?.originalError?.stack === 'string') {
            // eslint-disable-next-line no-console
            console.error(event);
            return !event.originalError.stack.includes('frmwrk/teele');
          }
        },
      });
      Bugsnag.getPlugin('vue').installVueErrorHandler(Vue);
    } else {
      // for dev env, mock bugnsag to see notify errors
      window._bugsnag = {
        start: () => {},
        setUser: () => {},
        addMetadata: () => {},
        notify: console.error,
      };
    }

    if (process.env.VUE_APP_INTERCOM_API_KEY) {
      WhitelabelService.ready(_ => {
        // only add intercom when theres no whitelabel
        if (WhitelabelService.intercomEnabled()) {
          require('@libs/Intercom');

          // intercom might be added later or slower, make sure to revalidate the user and currenet options
          IntercomService.revalidate();
        }
      });
    }

    // window.debug used for preview component and croptool
    if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_APPID !== 'preview') {
      window.debug = true;
      // make sure this is after setting API urls
      require('@libs/DevelopmentHelper');
    }

    if (process.env.VUE_APP_UI_LIBRARIES) {
      // add any UI libraries to the app
      HttpService.get(`ui/lib?appid=${Vue.prototype.$appid}`)
        .then(response => {
          for (const lib of response) {
            // teele is being built with arno, save reference to teele for the adcreator
            if (lib.name === 'arno') {
              Vue.prototype.$teele = lib.url.replace(lib.name, 'teele');
            }

            // define CDN version, but only for our build libs in dist dir (ignoring FW)
            // used to load other CDN scripts whenever needed
            // in future, might need v2 endpoint that will output versions separately from libs
            if (lib.url.startsWith(Vue.prototype.$cdn + 'dist')) {
              Vue.prototype.$cdn_version = lib.version;
              window.__nexd.cdn_version = lib.version;
            }

            // prevent adding the same library again, especially case for local hot reload development env
            const check = document.querySelector(`script[data-name=${lib.name}]`);
            if (!check) {
              const URL = lib.url;
              const script = appendScript(URL, lib.version ?? Vue.prototype.$version);
              script.setAttribute('data-name', lib.name);
            }
          }
        })
        .catch(() => {
          /** suppress errors */
        });
    }

    // init constants from local storage
    constants.init();

    // refresh constants
    HttpService.get(`ui/conf?appid=${Vue.prototype.$appid}`)
      .then(response => {
        constants.setup(response);
      })
      .catch(() => {
        /** suppress errors */
      });

    Vue.mixin({
      beforeCreate() {
        this.$http = HttpService;
        this.$user = UserService;
        this.$notifications = NotificationService;
        this.$global = {
          admin: UserService.admin,
        };

        // helper log fn to pretty print objects to console
        this.$log = function () {
          // disable this inner log fn for prod build
          if (process.env.NODE_ENV === 'production') return;

          for (const arg of arguments) {
            /* eslint-disable-next-line */
            console.log(JSON.stringify(arg, null, 4));
          }
        };
      },
    });
  },
};

export default GlobalServicePlugin;
