import Router from 'vue-router';
import Vue from 'vue';
Vue.use(Router);

import routes from '@root/src/router/routes';

const router = new Router({
  mode: process.env.NODE_ENV === 'development' ? 'hash' : 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = `${Vue.prototype.$whitelabel?.title ?? 'NEXD'} | ${to.meta.app_name}`;
  to.meta.loaded = true;
  next();
});

export default router;
