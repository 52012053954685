import Subscriber from '@libs/Subscriber';

class TemplatesService extends Subscriber {
  async subscribe(cb, vnode = null) {
    if (this.data == null) {
      await this.load();
    }

    super.subscribe(cb, vnode);
  }

  load() {
    return super.load('v2/templates/full', {}, true);
  }

  async get(id) {
    if (this.data == null) {
      await this.load();
    }

    return this.data.items?.find(template => {
      const unique_id = template.template_id ?? template.layout_id;
      return unique_id === id;
    });
  }

  bestForDesktop(template) {
    return this.#hasKeyword(template, 'best_for_desktop');
  }

  isAutoAnimationLayout(template) {
    return this.#hasKeyword(template, 'auto_animation');
  }

  isPopular(template) {
    return this.#hasKeyword(template, 'popular');
  }

  isNew(template) {
    return this.#hasKeyword(template, 'new');
  }

  #hasKeyword(template, key) {
    /**
     * @description Currently layout keywords are arrays ("[a,b]") and template keywords are strings ("a,b")
     */
    if (!template?.keywords?.length) {
      return false;
    }

    if (Array.isArray(template.keywords)) {
      return template.keywords.some(keyword => keyword.toLowerCase() === key);
    }

    return template.keywords.toLowerCase().includes(key);
  }

  defaultAssetColorWhite(template_id) {
    // for MVP. this data probably moves to backend in future
    return [
      '3ymiIL', // 3D Cube (vertical)
      'LBJ2A6', // 3D Cube (vertical)
      'Muc5vS', // 3D Showroom fill frames
      'PRZQ', // 3D Cube (horizontal)
      'SzZN', // 3D Prism (horizontal)
      'WoO2Hx', // 3D Cube (horizontal)
      'ayx27T', // 3D Cube (horizontal)
      'chPdON', // 3D Cube (horizontal)
      'e1H64x', // 3D Cube (horizontal)
      'nkcq', // 3D Prism (horizontal)
      'obUPMT', // 3D cube (horizontal)
      '3Jio', // Blinds swipe
      'fa4f0yzCZ8VyC7wU', // Blinds
      '48Ll', // Flip book
      'NPU68w', // Flip book
      'OrKKLW', // Flip
      'UUJ6S3', // Flip book
      'Z2FUMx', // Flip
      'w3Rp', // Flip book
      'sTVC4I', // Tiles
      'u1kqVH', // Tiles
      '7pxScw', // VR showroom
      'WB5RMF', // VR showroom
      'NCe8kc', // Roller (horizontal)
      'VGBF0t', // Roller (vertical)
      'eTBGOA', // Roller (horizontal)
      'pGQrqD', // Roller (vertical)
    ].includes(template_id);
  }
}

export default new TemplatesService();
