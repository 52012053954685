import { b64toBlob } from '@helpers/Global';

class AssetCollection {
  #assets = [];
  add(item) {
    let mime = item.type;
    if (mime === 'image/svg') {
      mime += '+xml';
    }

    const blob = b64toBlob(item.b64, mime);
    const file = new File([blob], item.filename, { type: mime });

    const asset = {
      filename: item.filename,
      mime: item.mime,
      file,
      blob: window.URL.createObjectURL(blob),
    };

    this.#assets.push(asset);
    return asset;
  }

  first() {
    return this.#assets[0] ?? null;
  }

  get() {
    return this.#assets;
  }
}

export default {
  collections: new Map(),

  get(key) {
    if (!this.collections.has(key)) {
      this.collections.set(key, new AssetCollection());
    }
    return this.collections.get(key);
  },
};
