import { clone, trimQuery } from '@helpers/Global';

export function convertOptions(options) {
  if (options?.constructor === Array) {
    return options;
  }

  return Object.entries(options ?? {}).reduce((acc, [key, value]) => {
    acc.push({
      value: key,
      label: value,
    });

    return acc;
  }, []);
}

export function filterOptions(options, search, show_first = true) {
  search = trimQuery(search);
  options = clone(options ?? []);

  if (!show_first) {
    options = options?.splice(1);
  }

  if (!search || search === '') {
    return options;
  }

  return options?.filter(option => {
    const search_terms = [option.value, option.label, option.description];

    return search_terms.some(term => term?.toLowerCase().includes(search?.toLowerCase()));
  });
}

export function getEnabledOptions(options) {
  options = clone(options ?? []);

  if (options?.constructor !== Array || options?.every(option => option.disabled)) {
    return [];
  }

  return options?.filter(option => !option.disabled).map(option => option.value);
}

export function selectionChanged(selection, old_selection) {
  const current_selection = new Set(selection ?? []);
  const previous_selection = new Set(old_selection ?? []);

  if (current_selection.size !== previous_selection.size) {
    return true;
  }

  for (const value of current_selection) {
    if (!previous_selection.has(value)) {
      return true;
    }
  }

  return false;
}

export function sortOptions(options, selection) {
  if (options?.constructor !== Array) {
    return [];
  }

  selection = selection ?? [];

  const grouped_options = options?.reduce(
    (acc, option) => {
      if (selection?.includes(option.value)) {
        acc[0].push(option);
      } else {
        acc[1].push(option);
      }

      return acc;
    },
    [[], []],
  );

  const sorted_options = grouped_options?.map(grouped_options =>
    grouped_options.sort((a, b) => {
      if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
      if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
      return 0;
    }),
  );

  return sorted_options?.flat();
}

export function generateTimes() {
  const output = [
    {
      value: null,
      label: 'Select time',
    },
  ];

  for (let hour = 0; hour < 24; hour++) {
    output.push({
      value: hour * 60 * 60,
      label: `${String(hour).padStart(2, 0)}:00`,
    });
  }

  return output;
}
