import { isDSPApp } from '@helpers/Global';

class NotificationService {
  #notifications = [];

  get() {
    return this.#notifications;
  }

  add(type, msg, close = 6000) {
    // Currently for DSP-s we enable only one notiification at a time
    if (isDSPApp()) {
      this.#notifications.forEach(el => clearTimeout(el.timeout));
      this.#notifications = [];
    }

    let new_notification = {
      type,
      msg,
      class: 'visible',
    };

    new_notification.timeout = setTimeout(() => {
      this.close(new_notification);
    }, close);

    this.#notifications.unshift(new_notification);
  }

  close(notification = null) {
    if (notification == null) return;

    clearTimeout(notification.timeout);

    notification.class = 'hide';

    notification.timeout = setTimeout(() => {
      this.#notifications.shift(this.#notifications.indexOf(notification), 1);
    }, 300);
  }
}

export default new NotificationService();
