<template lang="pug">
div#app(v-if="$route.meta.loaded")
  Notifications
  Navbar(:app-name="$route.meta.app_name")
  div#content
    router-view
</template>

<script>
import Navbar from '@master/UI/Navbar/Navbar.vue';
import Notifications from '@master/UI/Notifications.vue';

export default {
  name: 'App',

  components: {
    Navbar,
    Notifications,
  },

  created() {
    this.$user.init();
  },
};
</script>
