import Calculator from '@components/Calculator/Calculator.vue';
import { APPS } from '@master/constants';

const default_meta = {
  app_name: 'Ad Specs Calculator',
  app_key: APPS.ADSPECS,
  sidebar: false,
  icon: null,
  disabled: false,
};

export default [
  {
    path: '/',
    name: 'Calculator',
    component: Calculator,
    meta: { ...default_meta },
  },
  {
    path: '*',
    redirect: { name: 'Calculator' },
  },
];
