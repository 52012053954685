import AssetHelper from '@helpers/Asset';

export default (type, creative = null) => {
  const types = {
    '-1': 'Choose platform / size',
    x1: '@1x',
    x2: '@2x',
    'fb-1080': 'Square (1080x1080)',
    hq: 'High Quality',
    'landscape-16by9': 'Landscape 16:9 (1920x1080)',
    'portrait-9by16': 'Portrait 9:16 (1080x1920)',
  };

  if (creative != null) {
    const { width, height } = AssetHelper.getVideoExportSize(creative, type);

    // append export sizes that change with creative placement size
    types.x1 += ` (${width}x${height})`;
    types.x2 += ` (${width}x${height})`;
    types.hq += ` (${width}x${height})`;
  }

  return types[type] || type;
};
