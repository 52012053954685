export default class Storage {
  #id;
  #data;

  constructor(id) {
    if (!id) {
      throw new Error('id is required');
    }
    this.#id = id;

    try {
      this.#data = JSON.parse(localStorage.getItem(this.#id)).data ?? JSON.parse(localStorage.getItem(this.#id)) ?? null;
    } catch {
      this.#data = null;
    }
  }

  get() {
    return this.#data;
  }

  set(data) {
    this.#data = data;
    localStorage.setItem(this.#id, JSON.stringify({ data }));
  }

  remove() {
    localStorage.removeItem(this.#id);
  }
}
