<template lang="pug">
div(:class="styles.setting_row")
  div {{ label }}:
  slot
</template>

<script>
import styles from './Calculator.module.scss';

export default {
  name: 'SettingRow',

  props: {
    label: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      styles,
    };
  },
};
</script>
