import Service from '@libs/Service';

class Templates extends Service {
  #templates = {};
  #cache = new Map();

  async load() {
    let templates;
    try {
      templates = await this.$http.get('public/templates');
    } catch (error) {
      /** suppress fetch errors */
    }
    this.#handleTemplates(templates);
  }

  get() {
    return this.#templates;
  }

  getTemplateBase(template_id) {
    return this.#cache.get(template_id)?.template_base;
  }

  getTemplateName(template_id) {
    return this.#cache.get(template_id)?.name;
  }

  isSplitscreenCarousel(template_id) {
    return this.getTemplateBase(template_id)?.startsWith('splitCarouselVideo');
  }

  #handleTemplates(templates) {
    if (!templates?.length) {
      return;
    }

    for (const template of templates) {
      const template_id = template.template_id;
      const template_type = template.type;

      if (this.#cache.has(template_id)) {
        continue;
      }

      this.#cache.set(template_id, template);

      if (!(template_type in this.#templates)) {
        this.#templates[template_type] = [];
      }

      this.#templates[template_type].push({
        value: template_id,
        label: template.name,
        // enable next line to debug if template base gets correct main asset label
        // description: template.template_base,
      });
    }
  }
}

export default new Templates();
