<template lang="pug">
div(v-if="specs?.length > 0" :class="styles.container")
  section.mb-32.flex.flex-align-center.flex-justify-between.flex-wrap
    h2 Calculated specs
    span.flex.flex-align-center.hover-primary(@click="downloadCSV")
      | Download all specs as CSV
      i.nexd-icon-32-download(aria-hidden="true")

  div(:class="styles.tables")
    section(v-for="(spec, index) of specs" :key="index")
      section.flex.flex-align-center.mb-8
        h4.flex-grow
          template(v-for="(value, key, index) in getSpecOptionsData(spec.options)") {{ value }}
            template(v-if="index < 2") &nbsp;|&nbsp;
        i.nexd-icon-32-download.hover-primary(aria-hidden="true" v-tooltip="{value: 'Download specs as CSV'}" @click="downloadSpec(spec)")
        i.nexd-icon-32-delete.hover-primary(aria-hidden="true" v-tooltip="{value: 'Delete specs'}" @click="deleteTemplate(index)")

      table(:class="styles.table")
        thead
          tr
            th(v-for="value in headers" :key="value") {{ value }}
        tbody
          tr(v-for="(row, idx) of spec.items" :key="`${index}_${idx}`")
            td(v-for="(value, key) in row" :key="key" :data-head="headers[key]") {{ value }}
</template>

<script>
import styles from './SpecsTable.module.scss';

import TemplatesService from '@services/TemplatesService';

import { TYPE } from '@master/constants';
import { saveCSV } from '@helpers/Global';
import { getTypeLabel } from '@components/Calculator/utils';

const DELIMITER = ';';

export default {
  name: 'SpecsTable',

  props: {
    specs: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      styles,
      getTypeLabel,

      headers: {
        name: 'Asset name',
        size: 'Size (px)',
        quantity: 'Quantity',
        extensions: 'Supported files',
        required: 'Mandatory asset',
      },
    };
  },

  methods: {
    createCSVHeader() {
      return [
        'Layout name',
        'Placement size',
        'Placement type',
        ...Object.values(this.headers),
      ].join(DELIMITER);
    },

    createSpecRow(spec) {
      const { name, size, type } = this.getSpecOptionsData(spec.options);

      return spec.items
        .map(row => [name, size, type, ...Object.values(row)].join(DELIMITER))
        .join('\n');
    },

    getSpecOptionsData(options) {
      return {
        name: TemplatesService.getTemplateName(options.template_id),
        size: this.getSize(options),
        type: getTypeLabel(options.type) ?? 'Unknown',
      };
    },

    downloadCSV() {
      const csv = [
        this.createCSVHeader(),
        this.specs.map(spec => this.createSpecRow(spec)).join('\n\n'),
      ].join('\n');

      saveCSV(csv, 'ad_specs');
    },

    downloadSpec(spec) {
      const csv = [this.createCSVHeader(), this.createSpecRow(spec)].join('\n');

      const { name, size, type } = this.getSpecOptionsData(spec.options);
      const filename =
        `${name.replace(/ /g, '-')}_${size}_${type.replace(/ /g, '-')}`.toLowerCase();

      saveCSV(csv, filename);
    },

    async deleteTemplate(index) {
      if (await this.$confirm('Are you sure you want to delete specs?')) {
        this.specs.splice(index, 1);
        this.$emit('delete');
      }
    },

    getSize(options) {
      if (options.type === TYPE.INTERSTITIAL) {
        return 'Fullscreen';
      }

      return `${options.width}x${options.height}`;
    },
  },
};
</script>
