import { TYPE } from '@master/constants';

function typeMap() {
  const map = new Map();

  map.set(TYPE.INFEED, {
    value: TYPE.INFEED,
    label: 'Standard Infeed',
    description: 'It appears inside of content feeds.',
    icons: ['nexd-icon-16-mobile', 'nexd-icon-16-desktop'],
  });

  map.set(TYPE.INTERSTITIAL, {
    value: TYPE.INTERSTITIAL,
    label: 'Interstitial',
    description: 'Fullscreen ads that overlay the content.',
    icons: ['nexd-icon-16-mobile'],
  });

  map.set(TYPE.INTERSCROLLER, {
    value: TYPE.INTERSCROLLER,
    label: 'Interscroller',
    description:
      'Scroll-in ad, where creative is fullscreen size, but visible though a viewport (aka understitial or underlay).',
    icons: ['nexd-icon-16-mobile'],
  });

  return map;
}

export function getTypeLabel(type) {
  return typeMap().get(type)?.label;
}

export function typeOptions() {
  return Array.from(typeMap().values());
}

export function getType(type) {
  switch (type) {
    case TYPE.INTERSTITIAL:
    case TYPE.INTERSCROLLER:
      return TYPE.INTERSTITIAL;
    default:
      return TYPE.INFEED;
  }
}
