<template lang="pug">
Loading(v-if="loading")
div(v-else :class="styles.container")
  div(:class="styles.calculator")
    h1.fs-22.text-center Ad Specs Calculator
    p.mt-12.fs-14.color-gray-800.text-center
      | Input your ad details into the calculator and receive
      | all the asset specifications required for your creative.

    div.mt-32.flex.flex-direction-column.row-gap-16(v-if="creative != null")
      SettingRow(label="Ad type")
        SearchSelect(v-model="creative.type" :options="type_options" :search="false" :disabled="calculating" @change="onTypeChange")
      SettingRow(label="Size")
        div(v-if="creative.type === TYPE.INTERSTITIAL") Fullscreen
        CreativeSize(v-else :creative="creative" :disabled="calculating")
      SettingRow(label="Layout")
        SearchSelect(v-model="creative.template_id" :options="visible_templates_options" :disabled="calculating")

      Buttons
        Button(
          type="primary"
          label="Add"
          :loading="calculating"
          :disabled="!can_calculate"
          :tooltip="button_tooltip"
          @click="calculate"
        )

  SpecsTable(:specs="specs" @delete="saveStorage")
</template>

<script>
import styles from './Calculator.module.scss';

import CreativeSizeService from '@master/Services/CreativeSizeService';
import TemplatesService from '@services/TemplatesService';

import AssetHelper from '@helpers/Asset';
import { awaitLibrary, clone } from '@helpers/Global';
import { ASSET, SIZE, TYPE } from '@master/constants';
import { getType, typeOptions } from '@components/Calculator/utils';
import Storage from '@libs/Storage';

import CreativeTraits from '@master/Traits/CreativeTraits';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import CreativeSize from '@master/UI/CreativeSize/CreativeSize.vue';
import Loading from '@master/UI/Loading.vue';
import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';

import SettingRow from '@components/Calculator/SettingRow.vue';
import SpecsTable from '@components/SpecsTable/SpecsTable.vue';

const LOADED_TEMPLATE_ASSETS = new Map();

export default {
  name: 'Calculator',
  mixins: [CreativeTraits],

  components: {
    Button,
    Buttons,
    CreativeSize,
    Loading,
    SearchSelect,
    SettingRow,
    SpecsTable,
  },

  computed: {
    can_calculate() {
      for (const key in this.creative) {
        if (this.creative[key] == null || this.creative[key] === '') {
          return false;
        }
      }

      return !this.duplicate_entry && CreativeSizeService.validate(this.creative);
    },

    duplicate_entry() {
      for (const spec of this.specs) {
        if (spec.options.width === this.creative.width && spec.options.height === this.creative.height && spec.options.type === this.creative.type && spec.options.template_id === this.creative.template_id) {
          return true;
        }
      }

      return false;
    },

    button_tooltip() {
      if (this.duplicate_entry) {
        return {
          value: 'You have already calculated spec with those options.',
        };
      }

      if (!this.can_calculate) {
        return { value: 'Please fill out all fields' };
      }

      return null;
    },

    visible_templates_options() {
      return this.templates_options?.[getType(this.creative.type)] ?? [];
    },
  },

  data() {
    return {
      styles,
      TYPE,

      creative: {
        width: '',
        height: '',
        type: 0,
        template_id: null,
      },

      specs: [],
      loading: true,
      calculating: false,
      templates_options: {},
      type_options: typeOptions(),
      storage: new Storage('adspecs'),
    };
  },

  async created() {
    await this.initTemplates();
    this.loadFromStorage().then(specs => {
      this.specs = specs;
      this.loading = false;
    });
  },

  methods: {
    async initTemplates() {
      await TemplatesService.load();
      this.templates_options = TemplatesService.get();
      this.selectFirstTemplate();
    },

    selectFirstTemplate() {
      this.creative.template_id = this.visible_templates_options[0]?.value ?? null;
    },

    onTypeChange() {
      if (this.creative.type === TYPE.INTERSTITIAL) {
        this.creative.width = SIZE.INTERSTITIAL.WIDTH;
        this.creative.height = SIZE.INTERSTITIAL.HEIGHT_HIGH_ASPECT;
      }

      this.selectFirstTemplate();
    },

    async getTempalteAssets(template_id) {
      if (LOADED_TEMPLATE_ASSETS.has(template_id)) {
        return LOADED_TEMPLATE_ASSETS.get(template_id);
      }

      await this.loadTemplateAssets([template_id]);
      return LOADED_TEMPLATE_ASSETS.get(template_id);
    },

    async calculate() {
      if (this.calculating || !this.can_calculate) {
        return;
      }

      this.calculating = true;

      try {
        const options = clone(this.creative);
        const assets = await this.getTempalteAssets(options.template_id);
        const items = await this.updateAssetsData(assets);
        this.specs.unshift({
          options,
          items,
        });
        this.saveStorage();
      } catch (error) {
        /** suppress errors */
      } finally {
        this.calculating = false;
      }
    },

    async updateAssetsData(template_assets, options = this.creative) {
      const items = [];

      for (const asset of template_assets) {
        const size = await this.getSize(asset, options);
        const extensions = AssetHelper.getExtensionsFromFilename(asset.filename, asset.type).extensions;

        items.push({
          name: asset.name,
          size,
          quantity: asset.quantity,
          extensions,
          required: asset.required === 1 ? 'Yes' : 'No',
        });
      }

      return items;
    },

    async getSize(asset, _creative = this.creative) {
      if (AssetHelper.isMap(asset.filename)) {
        return '-';
      }

      const creative = this.mockCreative(_creative, asset);
      const options = {
        placement_width: creative.width,
        placement_height: creative.height,
        force_width: asset.width,
        force_height: asset.height,
      };

      if (asset.type === ASSET.OVERLAY) {
        options.force_width = null;
        options.force_height = null;
      }

      let size;
      if (TemplatesService.isSplitscreenCarousel(_creative.template_id)) {
        size = await this.getSizeFromArno(creative, asset);
      } else {
        size = AssetHelper.getSize(creative, asset.asset_id, options);
      }

      const width = size.recommended.width;
      const height = size.recommended.height;

      if (asset.type === ASSET.OVERLAY) {
        return `Up to ${width}x${height}`;
      }

      return `${width}x${height}`;
    },

    async getSizeFromArno(creative, asset) {
      return await awaitLibrary('Arno', 'Could not load Arno.', async _ => {
        const arno = await new window.Arno({
          templateBase: 'splitCarouselVideo',
          width: creative.width,
          height: creative.height,
          settings: {},
        });

        return AssetHelper.getSizeFromArno(arno, AssetHelper.getTemplateAssetForArno(asset), creative);
      });
    },

    mockCreative(creative, asset) {
      let device = 0;

      if (creative.type === TYPE.INFEED && creative.width >= 600) {
        device = 1;
      }

      return {
        width: creative.width,
        height: creative.height,
        device,
        type: creative.type,
        additional_assets: {},
        template: {
          assets: {
            [asset.asset_id]: asset,
          },
        },
      };
    },

    loadTemplateAssets(template_ids) {
      return this.$http.post('public/templates', { template_ids }).then(async response => {
        for (const template_id in response) {
          LOADED_TEMPLATE_ASSETS.set(template_id, response[template_id]);
        }
      });
    },

    saveStorage() {
      this.storage.set(this.specs.map(spec => spec.options));
    },

    loadFromStorage() {
      return new Promise(resolve => {
        const specs = [];
        const cache = this.storage.get();
        if (!cache?.length) {
          return resolve(specs);
        }

        const template_ids = Array.from(new Set(cache.map(item => item.template_id)));

        this.loadTemplateAssets(template_ids).then(async _ => {
          for (const options of cache) {
            const assets = LOADED_TEMPLATE_ASSETS.get(options.template_id) ?? null;
            if (!assets) {
              continue;
            }
            const items = await this.updateAssetsData(assets, options);
            specs.unshift({ options, items });
          }
          resolve(specs);
        });
      });
    },
  },
};
</script>
