class RequestLimiter {
  /**
   * @type {Map<string, number>}
   */
  #hooks = new Map();

  /**
   * @param {Node} vnode
   * @param {string} key
   * @param {Function} cb
   * @param {number} [timeout]
   */
  hook(vnode, key, cb, timeout = 1500) {
    if (key == null) {
      throw new Error('RequestLimiter can not be used without key');
    }

    this.#unbindBeforeDestroy(vnode, key);
    this.#unhook(key);

    if (typeof cb === 'function') {
      this.#hooks.set(
        key,
        setTimeout(() => {
          cb();
          this.#unhook(key);
        }, timeout),
      );
    }
  }

  #unhook(key) {
    if (this.#hooks.has(key)) {
      clearTimeout(this.#hooks.get(key));
      this.#hooks.delete(key);
    }
  }

  #unbindBeforeDestroy(vnode, key) {
    if (vnode != null && typeof vnode.$once === 'function') {
      vnode.$once('hook:beforeDestroy', _ => this.#unhook(key));
    }
  }
}

export default new RequestLimiter();
